<script>
import { bus } from "@/router/bus";
import MainInput from "./MainInput";
import md5 from "md5";
import {validateInn} from "@/utils/validators";
import {reachYMGoal} from "@/utils/metrika";

export default {
  name: "mt-login.vue",
  components: {
    MainInput,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: () => "login",
      validator: (value) => ["login", "signup", "mix"].includes(value),
    }
  },

  data() {
    return {
      profileData: {},
      valid: true,
      
      login: "",
      password: "",
      email: "",
      taxId: "",
      bankId: "",
      phone_number: "",
      taxNumber: '',
      inn_checked: false,
      showloader: false,
      companyInfo: {},
      is_signup: false,
      is_teo_contract: 0,
      is_privacy_check: 0,
      is_offer: 0,
      is_personal_data_check: 0,
      error: {
        code: null,
        message: null
      },
      rules: {
        login_required: value => !!value || 'Вы не указали логин...',
        login_counter: value => value.length >= 3 || 'Длина не менее 3 символов',
        pass_required: value => !!value || 'Вы не указали пароль...',
        pass_counter: value => value.length >= 5 || 'Длина не менее 5 символов',
        sender_required: value => !!value || 'Вы не указали адрес для регистрации...',
        sender_match: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Похоже, что введенный адрес некорректен...'
        },
      },
      isDisabled: false,
    }
  },

  methods: {
    checkInn() {
      this.error.message = null
      validateInn(this.taxNumber, this.error)
      if (this.error.code === null) {
        this.checkInnExternally()
      }
    },
    
    checkInnExternally() {
      this.$getapi(this.$address + this.$getters.getCompanyByInn.uri + this.taxNumber)
        .then(data => {
          if (data.companyname === null) {
            this.error.message = 'Неправильное контрольное число'
            this.error.code = 4
          } else {
            this.error.message = null
            this.error.code = null
          }
        });
    },

    getregisterlink(){
      if (this.email.length > 0) {
        this.isDisabled = true
        this.$postapi(this.$address + this.$getters.getRegisterLink.uri, {
          method: this.$getters.getRegisterLink.name,
          email: this.email,
          inn: this.taxNumber,
          is_teo_contract: 0,
          is_offer: 0,
        }).then(data => {
          if (data.error === 0) {
            this.$emit('close', 0)
            bus.$emit("show_notify", {
              color: "success",
              notifytext: "Сообщение направлено на указанную почту"
            });
            this.$router.push("/SelfRegistrationMessage")
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
            this.$emit('close', 0)
            this.isDisabled = false
          }
        });
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Вы не ввели электронный адрес!"
        });
      }
    },
    
    validate() {
      this.$refs.form.val
      if (this.valid === true) {
        this.getregisterlink()
      }
    },
    
    auth: function () {
      this.showloader = true
      if (this.login.length > 0 && this.password.length > 0) {
        let epoch = Math.round(new Date().getTime() / 1000);
        let hex = epoch.toString(16);
        this.$postapi(this.$address + this.$getters.auth.uri, {
          method: this.$getters.auth.name,
          user: this.login,
          key: epoch,
          mixed: this.$_mixed(md5(this.login), md5(this.password), hex),
          service: this.$serviceName
        }).then(data => {
          if (data.error == 0) {
            this.isUserInn(data)
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
            this.showloader = false
          }
        });
      } else {
        let message = this.login.length < 1 ? "Вы не ввели логин!" : this.password.length < 1 ? "Вы не ввели пароль!" : "";
        bus.$emit("show_notify", {
          color: "error",
          notifytext: message
        });
        this.showloader = false
        this.$_removesetting('token')
        this.$_storclear()
        this.$_setsetting('client_id', -99)
      }
    },
    
    isUserInn(data) {
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: data.token,
      }).then(user => {
        if (user.error == 0){
          this.profileData = JSON.parse(user.message)
          if (data.client_id == -1 || Object.prototype.hasOwnProperty.call(this.profileData, 'payers_data') === true) {
            this.$_setsetting('token', data.token)
            this.$_setsetting('user_id', data.user_id)
            this.$_setsetting('login', this.login)
            this.$_setsetting('email', this.profileData.email)
            this.$_setsetting('client_id', data.client_id)
            this.$_setsetting('fio', data.fio)
            this.$_setsetting('is_block', this.profileData.is_block)
            this.$_setsetting('is_auth', true)
            this.storeUserData(data.client_id)
            bus.$emit('auth-update');
            this.$emit('close', 0)
          } else {
            this.$_setsetting('token', data.token)
            this.$_setsetting('user_id', data.user_id)
            this.$_setsetting('is_block', this.profileData.is_block)
            this.$_setsetting('login', this.login)
            this.$_setsetting('email', data.email)
            this.$_setsetting('client_id', data.client_id)
            this.$_setsetting('fio', data.fio)
            if (document.documentElement.clientWidth > 743) {
              this.$router.push('/AddCompanyData')
            }
            this.$emit('close', 0)
          }
          this.showloader = false
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
          this.showloader = false
        }
      });
    },
    
    storeUserData(client_id) {
      this.$_setsetting('is_block', this.profileData.is_block)
      
      if (client_id != -1) {
        this.$_setsetting('is_added_block', this.profileData.is_added_block)
        this.$_setsetting('is_view_orders_block', this.profileData.is_view_orders_block)
        this.$_setsetting('is_view_fin_block', this.profileData.is_view_fin_block)
        
        if (this.profileData.payers_data[0].accounts_data[0] == null) {
          this.$_setsetting('req_checked', false)
        } else {
          this.$_setsetting('req_checked', true)
        }
        
        if (this.profileData.client_data[0]) {
          this.$_setsetting('is_show_bm_messages', this.profileData.client_data[0].is_show_bm_messages)
          
          if (this.profileData.client_data[0].user_email != null) {
            this.$_setsetting('clnt_name', this.profileData.client_data[0].clnt_name)
            this.$_setsetting('manager_email', this.profileData.client_data[0].user_email)
            this.$_setsetting('manager_name', this.profileData.client_data[0].user_name)
            this.$_setsetting('manager_phone', this.profileData.client_data[0].user_phone)
          } else {
            this.$_removesetting('clnt_name')
            this.$_removesetting('manager_email')
            this.$_removesetting('manager_name')
            this.$_removesetting('manager_phone')
          }
        }
        
      } else {
        this.$_setsetting('req_checked', true)
      }
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },
  }
}
</script>

<template>
  <div class="login-form">
    <div class="login-form__content">
      <div class="login-form__content__logo">
        <img
          class="login-form__content__logo-img"
          src="../../assets/img/vedexx-logo/logo_dark_vertical.svg"
          alt="vedexx-logo"/>
      </div>

      <div class="login-form__content__sigin">
        <div
          v-if="type=== 'mix'"
          class="login-form__content__sigin__header"
        >
          Авторизация
          <v-switch
            class="login-form__content__sigin__header-switch"
            v-model="is_signup"
            inset
            color="var(--bg-dark)"
          ></v-switch>
          Регистрация
        </div>

        <div
          v-else
          class="login-form__content__sigin__header"
          style="font-weight: 700; font-size: 22px"
        >
          {{ (type === 'login') ? "Авторизация" : "Регистрация" }}
        </div>

      <v-form
        v-if="(type === 'login' || (type === 'mix' && is_signup === false))"
        @submit.prevent="auth"
      >
        <div class="login-form__content__sigin__inputs">
          <main-input
            label="Логин"
            v-model.trim="login"
            type="text"
            :rules="[rules.login_required, rules.login_counter]"
            @keyup="auth"
            class="login-form__content__sigin__input"
          />
          <main-input
            label="Пароль"
            v-model.trim="password"
            type="password"
            :rules="[rules.pass_required, rules.pass_counter]"
            @click:append="sh_pass = !sh_pass"
            @keyup="auth"
            class="login-form__content__sigin__input"
          />

          </div>
          <div class="login-form__content__sigin__action">
            <v-btn
              v-if="showloader === false"
              class="login-form__content__sigin__action-btn"
              type="submit"
              text
              color="var(--main-bg-color)"
              @click="addMetrics('SignIn')"
            >
              Войти
            </v-btn>

            <v-progress-circular
              v-else
              size="24"
              :width="2"
              class="mx-3"
              indeterminate
              color="var(--main-bg-color)"
            ></v-progress-circular>

            <v-btn
              class="login-form__content__sigin__action-btn"
              text
              color="var(--main-bg-color)"
              @click="$emit('close', 0)"
            >
              Отмена
            </v-btn>
          </div>
        </v-form>

      <v-form
        v-else
        @submit.prevent="validate"
        ref="form"
        v-model="valid"
      >
        <div class="login-form__content__sigin__inputs">
          <main-input
            label="E-mail"
            v-model="email"
            type="email"
            :rules="[rules.sender_required, rules.sender_match]"
            @keyup="getregisterlink"
          />
          
          <main-input
            label="ИНН"
            v-model="taxNumber"
            type="number"
            @input="checkInn"
            :error="inn_checked ? '' : error.message"
          />
          <div class="login-form__content__sigin__inputs-checkbox">
            <v-checkbox
              color="var(--main-bg-color)"
              v-model="is_personal_data_check"
            />
            <div class="login-form__content__sigin__inputs-checkbox-text">
              Я согласен на
              <router-link :to="'document/8'" target="_blank">
                обработку персональных данных
              </router-link>
            </div>
          </div>

          <div class="login-form__content__sigin__inputs-checkbox">
            <v-checkbox
              color="var(--main-bg-color)"
              v-model="is_privacy_check"
            />
            <div class="login-form__content__sigin__inputs-checkbox-text">
              Подтверждаю, что я ознакомлен и согласен с условиями
              <router-link :to="'document/10'" target="_blank">
                политики конфидециальности
              </router-link>
              и
              <router-link :to="'document/9'" target="_blank">
                пользовательского соглашения
              </router-link>
            </div>
          </div>
        </div>
        <div class="login-form__content__sigin__action">
          <v-btn
            :disabled="(is_privacy_check && is_personal_data_check && taxNumber && valid > 0 ? false : true) || isDisabled"
            class="login-form__content__sigin__action-btn"
            type="submit"
            text
            color="var(--main-bg-color)"
            @click="addMetrics('registration')"
          >
            Создать
          </v-btn>
          <v-btn
            class="login-form__content__sigin__action-btn"
            text
            color="var(--main-bg-color)"
            @click="$emit('close', 0)"
          >
            Отмена
          </v-btn>
        </div>
      </v-form>
     </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login-form {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5% 10%;
  box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgba(96, 97, 99, 0.2),
  0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
  0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
  
  @media (max-width: $tablet-width) {
    padding: $size-48 $size-32;
  }
  
  @media (max-width: $mobile-width) {
    padding: $size-16;
  }

  &__content {
    width: 100%;
    flex-direction: row;
    display: flex;

    &__logo {
      width: 50%;
      
      @media (max-width: $tablet-width) {
       width: 30%;
      }
  
      @media (max-width: $mobile-width) {
        display: none;
      }
      
      &-img {
        top: 0;
        text-align: center;
        width: 80%;
      }
    }

    &__sigin {
      width: 50%;
      display: flex;
      flex-direction: column;
      
      @media (max-width: $tablet-width) {
        width: 70%;
      }
  
      @media (max-width: $mobile-width) {
        width: 100%;
      }

      &__header {
        @include font-headline-1;

        text-transform: uppercase;
        color: var(--main-bg-color);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        font-size: 20px;
        letter-spacing: -0.5px;
        font-weight: 600;

        &-switch {
          margin: 0 0 0 15px;

          @media (max-width: 400px){
            margin: 0 auto;
            max-height: 30px;
          }
        }

        @media (max-width: 400px){
          flex-direction: column;
          justify-content: center;
          align-content: center;
          gap: 10px;
          font-size: 24px;
        }
      }

      &__btn {
        text-transform: none;
        height: 35px;
        margin: 0 auto;
        font-weight: 700;
        background: var(--main-bg-color);
        font-size: 14px;
        line-height: 20px;
      }

      &__inputs {
        margin: 10px 0 15px;

        &-checkbox {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
        }
      }

      &__action {
        width: 100%;
        margin: 8px auto 0;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        font-family: $font-family;
      }
    }
  }

  &__info-banner {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}

.v-input--selection-controls {
  margin-top: 3px !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.login-form__content__sigin__input{
  font-family: $font-family !important;
}

.login-form__content__sigin__inputs-checkbox-text{
  @include font-subtitle-regular;
  font-size: 16px;
  line-height: 20px;
}
</style>
