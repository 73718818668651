<script>
import { bus } from "../../router/bus";
import ContactForm from "../common/ContactForm";
import { reachYMGoal } from "@/utils/metrika";

export default {
  name: "MainFooter.vue",
  
  components: {
    ContactForm
  },
  
  data: () => ({
    user_email: null,
    message: null,
    subject: null,
    contactInfo: [
      {
        city: "Санкт-Петербург",
        address: "196247, г. Санкт-Петербург, пл. Конституции д. 3,\n" +
          "корпус 2, литер А, ч.п./офис 70Н/1",
        phone: "+7(800)555-09-99",
        email: "spb@vedexx.com",
        goalName: 'SPB',
      },
      {
        city: "Калининград",
        address: "236006, г. Калининград проспект Ленинский  д. 30А, помещение 7, помещение 8",
        phone: "+7(981)704-43-09",
        email: "kgd@vedexx.com",
        goalName: 'KGD',
      },
      {
        city: "Владивосток",
        address: "690001, Г. Владивосток, ул. Светланская, д. 83, оф. 301",
        phone: "",
        email: "vvo@vedexx.com",
        goalName: 'VVO',
      },
      {
        city: "Находка",
        address: "692941, Врангель м-н, Находка, Приморский край Внутрипортовая, 19 офис 3\n",
        phone: "",
        email: "nhd@vedexx.com",
        goalName: 'NHK',
      }
    ]
  }),
  
  mounted() {
    if (this.$_getsetting('email')) {
      this.user_email = this.$_getsetting('email')
    }

    bus.$emit('getContactRef', (this.$refs))
  },
  
  methods: {
    sendContactForm() {
      this.$postapi(this.$address + this.$getters.setFeedBack.uri, {
        method: this.$getters.setFeedBack.name,
        bcc: '',
        cc: '',
        to: this.$_getsetting('manager_email') ? this.$_getsetting('manager_email') : 'help@vedexx.com',
        subject: this.subject,
        type: 'email',
        body: 'от: ' + this.$_getsetting('clnt_name') + ' почта: <' + this.user_email + '> текст: ' + this.message,
        atachments: [],
      }).then(data => {
        if (data.error == 0) {
          this.clearForm()
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
          this.clearForm()
        }
      });
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    clearForm() {
      this.message = ''
    }
  }
}
</script>

<template>
  <div class="new-footer">
    <div class="new-footer__container">
      <div class="main-footer__icons">
            <img src="../../assets/img/vedexx-logo/logo_light.svg" alt="vedexx-logo">
            <img src="../../assets/img/skolkovo-logo.svg" alt="skolkovo-logo">
          </div>
      <div
        class="main-footer"
      >
        <div class="main-footer__section1">
          <div class="main-footer__title">Остались вопросы? Свяжитесь с&nbsp;нами</div>
          <div
            class="main-footer__contacts"
          >
            <div
              class="main-footer__contacts__block"
              v-for="(city, index) in contactInfo"
              :key="index"
            >
              <div class="main-footer__contacts__block-name">{{city.city}}</div>
                <div class="main-footer__contacts-feedback">
                  <div class="main-footer__contacts__block-data" v-if="city.phone !=''">
                    <a :href="'tel:' + city.phone" @click="addMetrics(city.goalName + 'phonetap' + (city.goalName === 'SPB' ? 'footer' : ''))">
                      {{city.phone}}
                    </a>
                  </div>

                  <div class="main-footer__contacts__block-data">
                    <a :href="'mailto:' + city.email" @click="addMetrics(city.goalName + 'mailtap')">
                      {{city.email}}
                    </a>
                  </div>
                </div>
              <div class="main-footer__contacts__block-data main-footer__contacts__block-address">{{city.address}}</div>
            </div>
          </div>
          <a
            class="main-footer__social main-footer__social--telegram"
            @click="addMetrics('TelegramChannel')"
            href="https://t.me/vedexx_news"
            target="_blank"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_711_122)">
                <mask id="mask0_711_122" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <path d="M0 0H24V24H0V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_711_122)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12ZM12.43 8.859C11.2633 9.34433 8.93067 10.349 5.432 11.873C4.864 12.099 4.56633 12.32 4.539 12.536C4.493 12.902 4.951 13.046 5.573 13.241L5.836 13.325C6.449 13.524 7.273 13.757 7.701 13.766C8.08967 13.774 8.52367 13.614 9.003 13.286C12.271 11.0793 13.958 9.964 14.064 9.94C14.139 9.923 14.243 9.901 14.313 9.964C14.383 10.026 14.376 10.144 14.369 10.176C14.323 10.369 12.529 12.038 11.599 12.902C11.309 13.171 11.104 13.362 11.062 13.406C10.9667 13.5027 10.8727 13.5957 10.78 13.685C10.21 14.233 9.784 14.645 10.804 15.317C11.294 15.64 11.686 15.907 12.077 16.173C12.504 16.464 12.93 16.754 13.482 17.116C13.622 17.2093 13.757 17.3027 13.887 17.396C14.384 17.751 14.831 18.069 15.383 18.019C15.703 17.989 16.035 17.688 16.203 16.789C16.6 14.663 17.382 10.059 17.563 8.161C17.575 8.00343 17.5683 7.84499 17.543 7.689C17.529 7.56275 17.4675 7.44655 17.371 7.364C17.228 7.247 17.006 7.222 16.906 7.224C16.455 7.232 15.763 7.473 12.43 8.859Z" fill="white"/>
                </g>
              </g>
              <defs>
                <clipPath id="clip0_711_122">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div class="main-footer__section2">
          <ContactForm class="main-footer__form"/>
        </div>
      </div>
  </div>
  </div>
</template>

<style scoped lang="scss">
.main-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  color: #FFF;
  gap: 0 124px;
  position: relative;

  @media (max-width: $tablet-width){
    padding-top: 104px;
    margin: unset;
    gap: 0 55px;
  }

  @media (max-width: $mobile-width){
    flex-direction: column-reverse;
    padding-top: 86px;
  }

  @media (max-width: 400px){
    padding-top: 96px;
  }

  &__section1 {
    flex: 1;

    @media (max-width: $tablet-width) {
      flex: .9;
    }
  }
  
  &__section2 {
    flex: .79;

    @media (max-width: $tablet-width) {
      flex: 1;
    }
  }
  
  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 27px;
    color: #FFF;
    margin-bottom: 32px;

    @include font-headline-1;

    @media (max-width: $tablet-width){
      position: absolute;
      top: 32px;
      margin: unset;
    }

    @media (max-width: $mobile-width){
      @include font-body-bold-1;
      top: 24px;
    }

    @media (max-width: 400px){
      text-align: center;
    }
  }
  
  &__contacts {
    grid-template-columns: repeat(2, minmax(auto, 303px));
    grid-gap: 34px 37px;
    display: grid;
    width: 100%;

    @media (max-width: $tablet-width){
      grid-template-columns: 1fr;
      grid-gap: 32px;
    }

    @media (max-width: $mobile-width){
      margin-top: 24px;
      grid-gap: 24px;
    }
    
    &__block {
      position: relative;
      
      &-name {
        @include font-body-1;
        font-weight: 600;
        font-size: 16px;

        @include font-headline-3;
      }

      &-data {
        color: #FFF;

        @include font-description-1;
      }
    }

    &-feedback{
      margin: 10px 0;
      display: flex;
      align-items: center;
      gap: 0 30px;

      a{
        color: #FFF !important;

        @include font-description-1;

        > i{
          display: none;
        }
      }
    }
  }

  &__icons{
    display: flex;
    align-items: center;
    gap: 0 67px;
    flex-wrap: wrap;

    img{
      max-width: 155px;
      object-fit: contain;
    }

    @media (max-width: $mobile-width){
      gap: 21px;
    }

    @media (max-width: 359px){
      justify-content: center;
    }
  }

  &__social{
    margin-top: 32px;
    display: inline-block;

    @media (max-width: $tablet-width){
      &--telegram{
        svg{
          width: 32px;
          height: 32px;
        }
      }
    }

    @media (max-width: $mobile-width){
      margin-top: 24px;
    }
  }
}

a {
  text-decoration: underline;
  color: var(--bg-dark) !important;
  
  &:hover {
    text-decoration: underline;
  }
}

.new-footer{
  margin-top: 60px;
  background-color: var(--grey-70);

  &__container{
    max-width: 1440px;
    margin: 0 auto;
    padding: 44px 0px;
    width: calc(100% - 64px * 2);

    @media (max-width: $tablet-width){
      padding: 32px;
      width: 100%;
    }

    @media (max-width: $mobile-width){
      padding: 16px;
    }
  }
}


</style>
