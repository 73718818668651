<script>
import { reachYMGoal } from "@/utils/metrika";
import { bus } from "../../router/bus";

export default {
  name: "MainHeader.vue",

  props: {
    type: {
      type: String,
      required: false,
      default: () => "light",
      validator: (value) => ["light", "dark" ].includes(value),
    }
  },

  data: () => ({
    isSetting: 0,
    authStatus: !!localStorage.getItem('is_auth')
  }),

  computed: {
    isAuth() {
      return this.authStatus;
    },

    isToken() {
      return !!this.$_getsetting('token');
    },
  },

  mounted() {
    this.checkIsSiteAvailable()

    if (!this.isAuth && !this.$_getsetting('client_id')) {
      this.$_setsetting('client_id', -99)
    }

    bus.$on("auth-update", () => {
      this.authStatus = !!this.$_getsetting('is_auth')
    })
  },

  methods: {
    showLoginForm(data) {
      bus.$emit("showloginform", data)
    },

    gotoMain() {
      if (this.$route.name !== "MainPage") {
        this.$router.push("/")
      } else {
        bus.$emit('hide_rate')
      }
    },

    gotoProfile() {
      if (this.$route.name !== "Profile") {
        this.$router.push("/Profile")
      }
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    checkIsSiteAvailable() {
      this.$postapi(this.$address + this.$setters.getSettingByName.uri, {
        method: this.$setters.getSettingByName.name,
        setting_name: "is_under_construction"
      }).then(data => {
        if (data.error == 0) {
          this.isSetting = JSON.parse(data.message)
          if (this.isSetting.is_under_construction == 1) {
            this.$router.push("/ErrorPage")
          }
        }
      });
    },
  }
}
</script>

<template>
  <div
    class="main-header"
    :class="type === 'light' ? 'main-header-light' : 'main-header-dark'"
  >
  <div class="main-header__info-items">
    <div
      class="main-header__logo"
      @click="gotoMain(), addMetrics('LogoTap')"
    >
      <img src="../../assets/img/vedexx-logo/logo_light.svg" alt="vedexx-logo"/>
    </div>

    <div class="main-header__login-btns-contact_links">
        <a href="tel:+78005550999"  @click="addMetrics('SPBphonetap')">+7(800) 555-09-99</a>
        <a href="mailto:spb@vedexx.com" @click="addMetrics('Email')">spb@vedexx.com</a>
      </div>

  </div>

    <div class="main-header__login-btns">

      <div v-if="!isAuth">
        <v-btn
          class="main-header__login-btns-btn"
          text
          dark
          color="white"
          @click="showLoginForm('login')"
        >
          <v-icon dark class="mr-2">
            mdi-account
          </v-icon>
          Войти
        </v-btn>

        <div class="main-header__login-btns-divider"></div>

        <v-btn
          class="main-header__login-btns-btn"
          text
          dark
          color="white"
          @click="showLoginForm('signup')"
        >
          Регистрация
        </v-btn>
      </div>

      <div v-else>
        <v-btn
          class="main-header__login-btns-btn"
          text
          dark
          color="white"
          @click="gotoProfile()"
        >
          <v-icon class="mr-2" dark>
            mdi-account
          </v-icon>
          {{ $_getsetting('login') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-header-light {
  height: 84px;
  line-height: 84px;

  @media (max-width: 743px) {
    display: none;
  }
}

.main-header-dark {
  height: 72px;
  line-height: 68px;

  @media (max-width: 743px) {
    display: none;
  }
}

.main-header {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0;
  align-items: center;
  align-content: center;

  @media (max-width: 700px) {
    display: none;
  }

  &__logo {
    position: relative;
    cursor: pointer;

    > img {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 100%;
      height: auto;
      max-width: 121px;


      @media (max-width: 600px) {
        position: relative;
        width: 100%;
      }
    }

    &-mobile-text {
      text-transform: uppercase;
      color: var(--main-bg-color);
      font-weight: 500;
      font-family: 'Troverst', sans-serif;
      font-style: normal;
      display: inline;
      align-items: center;
      position: absolute;
      font-size: 25px;
      line-height: 28px;
      margin-left: -110px;
      top: 4px;
      margin-top: 20px;
      letter-spacing: 0.01em;

      @media (min-width: 601px) {
        display: block;
      }
    }
  }

  &__login-btns {
    float: right;
    position: relative;
    display: flex;
    flex-direction: row;

    &-contact_links {
      color: white;
      text-align: end;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      line-height: 12px;
      top: 0;
      margin: auto $size-12;

      @media (min-width: 1919px) {
        font-size: $size-16;
        margin: auto $size-16;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }

    &-btn {
      text-transform: none;

      @include font-description-semibold-1;
      font-weight: 700;

      @media (max-width: 600px) {
        display: none;
      }
    }

    &-mobile {
      display: none;
      margin-top: 10px;

      @media (max-width: 600px) {
        display: inline-block;
      }
    }

    &-divider {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px 0;
      width: 1.5px;
      height: 30px;
      background: #FFFFFF;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  &__info-items{
    display: flex;
    align-items: center;
    gap: 0 24px;

    .main-header__login-btns-contact_links{
      display: flex;
      flex-direction: column;
      gap: 6px 0;

      a {
        @include font-description-1;
      }
    }
  }
}

a {
  color: white !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.main-header__login-btns-contact_link{
    @include font-body-2;
    line-height: 14px;
}

</style>

