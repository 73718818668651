import Vue from "vue";
import Router from "vue-router";

const RatePage = () => import(/* webpackChunkName: "rate-page" */ "../components/rate-page-new/RatePage.vue");
const MainPage = () => import(/* webpackChunkName: "main-page" */ "../components/main-page/MainPage.vue");

const Profile = () => import(/* webpackChunkName: "profile" */ "../components/profile/Profile.vue");
const SelfRegistrationContinue = () => import(/* webpackChunkName: "profile" */ "../components/profile/SelfRegistrationContinue.vue");
const SelfRegistrationMessage = () => import(/* webpackChunkName: "profile" */ "../components/profile/SelfRegistrationMessage.vue");
const AddCompanyData = () => import(/* webpackChunkName: "profile" */ "../components/profile/forms/AddCompanyData.vue");
const LowRatingForm = () => import(/* webpackChunkName: "profile" */ "../components/profile/forms/LowRatingForm.vue");
const Cabinet = () => import(/* webpackChunkName: "profile" */ "../components/cabinet/Cabinet.vue");

const Article = () => import(/* webpackChunkName: "content-page" */ "../components/common/Article.vue");
const Document = () => import(/* webpackChunkName: "content-page" */ "../components/common/Document.vue");
const DirectorMessage = () => import(/* webpackChunkName: "content-page" */ "../components/common/DirectorMessage.vue");
const TeamPage = () => import(/* webpackChunkName: "content-page" */ "../components/common/TeamPage.vue");
const RatingPage = () => import(/* webpackChunkName: "content-page" */ "../components/rate-raiting/RatingPage.vue");
const MediaArticleListPage = () => import(/* webpackChunkName: "content-page" */ "../components/main-page/mass-media/MediaArticleListPage.vue");
const ErrorPage = () => import(/* webpackChunkName: "content-page" */ "../components/error-page/ErrorPage.vue");
const CloseSubscription = () => import(/* webpackChunkName: "content-page" */ "../components/common/CloseSubscription.vue");
const MobileContactPage = () => import(/* webpackChunkName: "content-page" */ "../components/main-page/mobile-pages/MobileContactPage.vue");
const MobileVideoPage = () => import(/* webpackChunkName: "content-page" */ "../components/main-page/mobile-pages/MobileVideoPage.vue");
const NewsList = () => import(/* webpackChunkName: "content-page" */ "../components/main-page/news/NewsList.vue");
const NewsPage = () => import(/* webpackChunkName: "content-page" */ "../components/main-page/news/NewsPage.vue");

// const RateList = () => import("../components/rate-list/RateList.vue");
// const RateCardByID = () => import("../components/rate-page-archive/RateCardByID.vue");
// const ComplexRateList = () => import("../components/complex-rate/RateComplexList.vue");
// const CompareRateList = () => import("../components/compare-rate/RateCompareList.vue");
Vue.use(Router);

export default new Router({
  hashbang: false,
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "MainPage",
      component: MainPage,
      meta: {
        layout:'DefaultLayout'
      }
    },
    
    {
      path: "/Cabinet",
      name: "Cabinet",
      component: Cabinet,
      meta: {
        layout:'LightLayout'
      },
      beforeEnter: (to, from, next) => {
        if (!localStorage.getItem('is_auth')) {
          next('/')
        } else {
          next()
        }
      }
    },
    
    {
      path: "/Article/:id",
      name: "Article",
      component: Article,
      meta: {
        layout:'DarkLayout'
      }
    },
    
    {
      path: "/document/:id",
      name: "Document",
      component: Document,
      meta: {
        layout:'DarkLayout'
      }
    },
  
    {
      path: "/NewsList",
      name: "NewsList",
      component: NewsList,
      meta: {
        layout:'LightLayout'
      }
    },
    
    {
      path: "/team",
      name: "TeamPage",
      component: TeamPage,
      meta: {
        layout:'LightLayout'
      }
    },
    
    {
      path: "/Contact",
      name: "Contact",
      component: MobileContactPage,
      meta: {
        layout:'LightLayout'
      }
    },
    
    {
      path: "/VideoPage",
      name: "VideoPage",
      component: MobileVideoPage,
      meta: {
        layout:'LightLayout'
      }
    },
    
    {
      path: "/NewsPage/:id",
      name: "NewsPage",
      component: NewsPage,
      meta: {
        layout:'LightLayout'
      }
    },
    
    // {
    //   path: "/RateList",
    //   name: "RateList",
    //   component: RateList,
    //   meta: {
    //     layout:'DarkLayout'
    //   }
    // },
    
    {
      path: "/MassMedia",
      name: "MassMedia",
      component: MediaArticleListPage,
      meta: {
        layout:'LightLayout'
      }
    },
    
    {
      path: "/team",
      name: "team",
      component: TeamPage,
      meta: {
        layout:'LightLayout'
      }
    },
    
    {
      path: "/ErrorPage",
      name: "error",
      component: ErrorPage,
      meta: {
        layout:'EmptyLayout'
      }
    },
    
    {
      path: "/DirectorMessage",
      name: "DirectorMessage",
      component: DirectorMessage,
      meta: {
        layout:'LightLayout'
      }
    },

    // {
    //   path: "/RateCardByID",
    //   name: "RateCardByID",
    //   component: RateCardByID,
    //   meta: {
    //     layout:'DefaultLayout'
    //   }
    // },
    {
      path: "/RatePage",
      name: "RatePage",
      component: RatePage,
      meta: {
        layout:'RatePageLayout'
      },
      beforeEnter: (to, from, next) => {
        if (!localStorage.getItem('is_auth')) {
          next('/')
        } else {
          next()
        }
      }
    },
    
    // {
    //   path: "/ComplexRateList",
    //   name: "ComplexRateList",
    //   component: ComplexRateList,
    //   meta: {
    //     layout:'DefaultLayout'
    //   }
    // },
    // {
    //   path: "/CompareRateList",
    //   name: "CompareRateList",
    //   component: CompareRateList,
    //   meta: {
    //     layout:'DarkLayout'
    //   }
    // },
    {
      path: "/Profile",
      name: "Profile",
      component: Profile,
      meta: {
        layout:'LightLayout'
      },
      beforeEnter: (to, from, next) => {
        if (!localStorage.getItem('is_auth')) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: "/SelfRegistrationContinue",
      name: "SelfRegistrationContinue",
      component: SelfRegistrationContinue,
      meta: {
        layout:'DarkLayout'
      },
    },
    
    {
      path: "/CloseSubscription",
      name: "CloseSubscription",
      component: CloseSubscription,
      meta: {
        layout:'DarkLayout'
      },
    },
    
    {
      path: "/RatingPage",
      name: "RatingPage",
      component: RatingPage,
      meta: {
        layout:'DarkLayout'
      },
    },
    {
      path: "/SelfRegistrationMessage",
      name: "SelfRegistrationMessage",
      component: SelfRegistrationMessage,
      meta: {
        layout:'DarkLayout'
      }
    },
    {
      path: "/AddCompanyData",
      name: "AddCompanyData",
      component: AddCompanyData,
      meta: {
        layout:'DarkLayout'
      }
    },
    
    {
      path: "/LowRatingForm",
      name: "LowRatingForm",
      component: LowRatingForm,
      meta: {
        layout:'DarkLayout'
      }
    },
  ],
  // scrollBehavior() {
  //     document.getElementById('app').scrollIntoView();
  //   }
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
  });
